<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import { currencyFormat } from '@/libs/utils'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_keuangan',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          order: 1,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Jenis Dana',
          attributes: { colspan: 5 },
        }],
        [{
          label: 'Pendidikan/mahasiswa/tahun',
          name: 'pendidikan_mhs_tahun',
          dataKey: 'pendidikan_mhs_tahun',
          order: 2,
          render(data) {
            return currencyFormat(data)
          }
        }, {
          label: 'Penelitian/dosen/tahun',
          name: 'penelitian_dosen_tahun',
          dataKey: 'penelitian_dosen_tahun',
          order: 3,
          render(data) {
            return currencyFormat(data)
          }
        }, {
          label: 'PkM/dosen/tahun',
          name: 'pkm_dosen_tahun',
          dataKey: 'pkm_dosen_tahun',
          order: 4,
          render(data) {
            return currencyFormat(data)
          }
        }, {
          label: 'Publikasi/dosen/tahun',
          name: 'publikasi_dosen_tahun',
          dataKey: 'publikasi_dosen_tahun',
          order: 5,
          render(data) {
            return currencyFormat(data)
          }
        }, {
          label: 'Investasi/tahun',
          name: 'investasi_tahun',
          dataKey: 'investasi_tahun',
          order: 6,
          render(data) {
            return currencyFormat(data)
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'textarea',
          rules: 'required',
          placeholder: moment().format('YYYY')
        }, {
          label: 'Pendidikan/mahasiswa/tahun',
          name: 'pendidikan_mhs_tahun',
          dataKey: 'pendidikan_mhs_tahun',
          type: 'currency',
          
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'Penelitian/dosen/tahun',
          name: 'penelitian_dosen_tahun',
          dataKey: 'penelitian_dosen_tahun',
          type: 'currency',
          
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'PkM/dosen/tahun',
          name: 'pkm_dosen_tahun',
          dataKey: 'pkm_dosen_tahun',
          type: 'currency',
          
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'Publikasi/dosen/tahun',
          name: 'publikasi_dosen_tahun',
          dataKey: 'publikasi_dosen_tahun',
          type: 'currency',
          
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }, {
          label: 'Investasi/tahun',
          name: 'investasi_tahun',
          dataKey: 'investasi_tahun',
          type: 'currency',
          
          defaultValue: 0,
          currencyConfig: { precision: 0 }
        }]
      }
    }
  },
}
</script>
